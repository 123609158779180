import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import "./Privacy.css"
class Privacy extends Component {

  navigateTo = (type) => {
    console.log("navigateToHome", this.props)
    this.props.history.push('/' + type)
  }

  state = {
    tfn: null
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  componentWillUnmount() {
    window.scrollTo(0, 0)
  }

  setUrl = (url) => {
    let searchParams = new URLSearchParams(window.location.search);
    searchParams.set("tfn", url);
    let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + "?" + searchParams.toString();
    window.history.pushState({ path: newurl }, "", newurl);
    window.scrollTo(0, 0)
    this.setState({ tfn: url })
  }

  getTfn = (tfn) => {
    let temp = ""
    for (let i = 0; i < tfn.length; i++) {
      if (tfn[i] !== "-") {
        temp = temp + tfn[i]
      }
    }
    return "tel:+" + temp
  }

  getTfnFormat = (tfn) => {
    let _tfn = ""
    // if(tfn.length === 14){
    tfn = tfn.split('-')
    _tfn = "(" + tfn[1] + ") " + tfn[2] + "-" + tfn[3]
    // }
    return _tfn
  }
  handleClick = () => {
    if (typeof window.gtag === 'function') {
      const phoneNumber = this.state.tfn || "1-833-525-6234";
      window.gtag('event', 'conversion', {
        'send_to': 'AW-606595014/9F__CNHTn94DEMbPn6EC',
        'phone_number': phoneNumber,
      });

    } else {
      console.error('Google Analytics gtag function is not defined.');
    }
  };
  render() {
    const urlParams = new URLSearchParams(window.location.search);
    let tfn = urlParams.get("tfn");
    return (
      <>

        <body className="home page-template-default page page-id-6 wp-custom-logo bridge-core-2.0.8  qode-title-hidden qode_grid_1300 footer_responsive_adv qode-content-sidebar-responsive qode-overridden-elementors-fonts qode-child-theme-ver-1.1.9.2022.07.25 qode-theme-ver-19.5 qode-theme-bridge qode_advanced_footer_responsive_1000 wpb-js-composer js-comp-ver-6.9.0 vc_responsive elementor-default elementor-template-full-width elementor-kit-1771 elementor-page elementor-page-6">
          <div
            data-elementor-type="header"
            data-elementor-id="37130"
            className="elementor elementor-37130 elementor-location-header"
          >
            <header
              className="elementor-section elementor-top-section elementor-element elementor-element-afdff74 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default parallax_section_no qode_elementor_container_no"
              data-id="afdff74"
              data-element_type="section"
              data-settings='{"background_background":"classNameic","sticky":"top","sticky_on":["desktop","tablet","mobile"],"sticky_offset":0,"sticky_effects_offset":0}'
            >
              <div className="elementor-container elementor-column-gap-default">
                <div
                  className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-58edd96"
                  data-id="58edd96"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-ebb3272 nolazy elementor-widget elementor-widget-theme-site-logo elementor-widget-image"
                      data-id="ebb3272"
                      data-element_type="widget"
                      data-widget_type="theme-site-logo.default"
                    >
                      <div className="elementor-widget-container">
                        <a href="/">
                          {/* <img width="252" height="40" className="attachment-full size-full" alt="Cable Internet In My Area" loading="lazy" /> */}
                          <p>Shop Fiber Internet</p>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-fe63449"
                  data-id="fe63449"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-43c0836 elementor-nav-menu--stretch elementor-nav-menu__align-center elementor-nav-menu--dropdown-tablet elementor-nav-menu__text-align-aside elementor-nav-menu--toggle elementor-nav-menu--burger elementor-widget elementor-widget-nav-menu"
                      data-id="43c0836"
                      data-element_type="widget"
                      data-widget_type="nav-menu.default"
                    >
                      <div className="elementor-widget-container">
                        <nav
                          migration_allowed="1"
                          migrated="0"
                          role="navigation"
                          className="elementor-nav-menu--main elementor-nav-menu__container elementor-nav-menu--layout-horizontal e--pointer-none"
                        >
                          <ul id="menu-1-43c0836" className="elementor-nav-menu">
                            <li className="elementor-button menu-item menu-item-type-custom menu-item-object-custom menu-item-690">
                              <a onClick={this.handleClick} href={tfn === null ? "tel:+18335256234" : this.getTfn(tfn)} className="elementor-item">{tfn === null ? this.getTfnFormat("1-833-525-6234") : this.getTfnFormat(tfn)} </a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mobno">
                <div className="elementor-button-wrapper">
                  <a onClick={this.handleClick} href={tfn === null ? "tel:+18335256234" : this.getTfn(tfn)} className="elementor-button-link elementor-button elementor-size-xs">{tfn === null ? this.getTfnFormat("1-833-525-6234") : this.getTfnFormat(tfn)} </a>
                </div>
              </div>
            </header>
          </div>

          <div
            data-elementor-type="wp-page"
            data-elementor-id="6"
            className="elementor elementor-6"
          >
            <div
              data-elementor-type="footer"
              data-elementor-id="37142"
              className="elementor elementor-37142 elementor-location-footer"
            >
              <section
                className="elementor-section elementor-top-section elementor-element elementor-element-5bb7bb0e elementor-section-boxed elementor-section-height-default elementor-section-height-default parallax_section_no qode_elementor_container_no"
                data-id="5bb7bb0e"
                data-element_type="section"
                data-settings='{"background_background":"gradient"}'
              >
                <div className="elementor-container elementor-column-gap-default">
                  <div
                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-4049adc2"
                    data-id="4049adc2"
                    data-element_type="column"
                  >
                    <div className="elementor-widget-wrap elementor-element-populated">
                      <div
                        className="elementor-element elementor-element-238d1431 elementor-widget elementor-widget-heading"
                        data-id="238d1431"
                        data-element_type="widget"
                        data-widget_type="heading.default"
                      >
                        <div className="elementor-widget-container">
                          <h2 className="elementor-heading-title elementor-size-default">
                            Privacy Policy
                          </h2>{" "}
                        </div>
                      </div>
                      <div
                        className="elementor-element elementor-element-7c53c1e5 elementor-widget__width-auto elementor-absolute elementor-view-default elementor-widget elementor-widget-icon"
                        data-id="7c53c1e5"
                        data-element_type="widget"
                        data-settings='{"_position":"absolute"}'
                        data-widget_type="icon.default"
                      >
                        <div className="elementor-widget-container">
                          <div className="elementor-icon-wrapper">
                            <div className="elementor-icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="127"
                                height="170"
                                viewBox="0 0 127 170"
                                fill="none"
                              >
                                <path
                                  d="M16.3535 167.023C13.2701 157.984 9.67228 148.919 5.81936 140.198C25.1711 155.6 55.3682 154.682 73.7267 138.061C85.7206 127.391 91.3361 110.929 90.1826 94.9643C98.2016 93.2605 106.033 88.9292 111.739 82.6674C121.645 71.6954 126.355 56.6963 126.209 42.0111C125.749 27.2706 121.015 9.89772 108.646 0.912211C108.072 0.486964 107.391 1.40522 107.965 1.83047C125.225 16.2163 126.835 46.2076 118.609 65.7129C113.397 78.0291 102.292 89.1795 89.7359 91.9668C88.8935 84.943 86.6522 78.1273 82.9866 72.0341C78.7426 64.8015 70.2848 55.3361 60.6713 57.992C50.9431 60.5628 52.5939 73.6964 56.2638 80.5038C62.851 92.4943 74.5356 96.8837 86.1858 95.5589C87.1904 110.525 82.0255 125.898 70.5418 135.879C53.5438 150.664 27.8282 150.813 9.66723 138.604C17.7756 137.5 25.8244 135.996 33.8136 134.092C35.0976 133.799 34.7998 131.801 33.5158 132.094C23.0392 133.755 12.673 134.787 2.19212 135.733C0.99309 135.912 0.287375 137.344 0.750918 138.399C5.09717 148.374 9.15855 158.493 12.7352 168.787C13.6623 170.896 17.1105 169.362 16.3535 167.023ZM68.1953 87.9204C64.3514 85.4283 60.9581 81.848 58.9891 77.545C56.5268 71.9878 55.8074 61.6774 63.9499 61.4873C73.9206 61.2294 80.9161 74.5891 83.6209 82.4592C84.7267 85.7672 85.5475 89.2196 86.0538 92.6168C80.1989 93.0793 74.076 91.7434 68.1953 87.9204Z"
                                  fill="white"
                                ></path>
                              </svg>{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                      <section
                        className="elementor-section elementor-inner-section elementor-element elementor-element-36374419 elementor-section-boxed elementor-section-height-default elementor-section-height-default parallax_section_no qode_elementor_container_no"
                        data-id="36374419"
                        data-element_type="section"
                      >
                        <div className="elementor-container elementor-column-gap-no">
                          <div
                            className="elementor-column elementor-inner-column elementor-element"
                            data-id="55227fef"
                            data-element_type="column"
                          >
                            <div className="elementor-widget-wrap elementor-element-populated">
                              <div
                                className="elementor-element elementor-element-32b4737 elementor-position-left elementor-mobile-position-left elementor-view-stacked elementor-shape-square elementor-vertical-align-top elementor-widget elementor-widget-icon-box"
                                data-id="32b4737"
                                data-element_type="widget"
                                data-widget_type="icon-box.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="elementor-icon-box-wrapper">
                                    <div className="elementor-icon-box-content">
                                      <h5 className="elementor-icon-box-title">
                                        <span>1. General </span>
                                      </h5>
                                      <p className="elementor-icon-box-description">
                                        if you are visiting the site from outside the United States, be advised that your personal data will be processed by ShopFiberInternet.com in the United States, where data protection and privacy standards may differ from similar regulations in other countries. By using the Web site, you consent to any transfer of information outside of your country if you are outside the United States. Furthermore, by using the Website, you agree ShopFiberInternet.com use your personal information to contact you electronically, in writing or otherwise to provide notices relating to your use of the Website and to provide information about products and services offered by ShopFiberInternet.com. If you do not wish to receive future promotional materials from us, you can indicate your preference by following the unsubscribe instructions at the end of such communications from us.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="elementor-widget-container">
                                  <div className="elementor-icon-box-wrapper">
                                    <div className="elementor-icon-box-content">
                                      <h5 className="elementor-icon-box-title">
                                        <span>2. Information Gathering / Data processing </span>
                                      </h5>
                                      <p className="elementor-icon-box-description">
                                        We will never collect sensitive information about you without your explicit consent. When you register to become a client with ShopFiberInternet.com completing and submitting a form on the website, an account will be created automatically. The registration process will collect your name, address, phone number, email address and information about the location and demographics. When you visit the website ShopFiberInternet.com, our web servers may use technology to track the patterns of behavior of visitors to our site by collecting number of visits, average time spent, pages viewed, domain name used to access Internet and come to visit website.
                                        Furthermore, We use information as mentioned, Your email address, your name and your contact number is just used for communication between you and us, Your IP address is tracked and used for the purpose fraud prevention and your card details and billing information is only disclose to our merchant gateway and your financial institution like bank or card issuer.                                 </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="elementor-widget-container">
                                  <div className="elementor-icon-box-wrapper">
                                    <div className="elementor-icon-box-content">
                                      <h5 className="elementor-icon-box-title">
                                        <span>3. Cookies and Log Files </span>
                                      </h5>
                                      <p className="elementor-icon-box-description">
                                        Cookies may be used on some pages of our site. "Cookies" are small text files placed on your hard drive that helps us provide a more personalized site experience. For example, a cookie can be used to store registration information in an area of the site so that a user does not have to re-enter subsequent visits to that area. Policy is ShopFiberInternet.com use cookies to facilitate navigation of websites easier for visitors and to facilitate efficient registration procedures.If you are concerned about cookies, most browsers permit individuals to decline cookies. In most cases, a visitor may refuse a cookie and still fully navigate our website, however other features on the website may be impaired. After completion of the visit to our site, you can always delete the cookie from your system if desired.In order to properly manage our website may anonymously log information on our operational systems, and identify categories of visitors by items such as domains and browser types. These statistics are presented in the aggregate to our webmasters. This is to ensure that our website presents the best experience for our visitors and is an effective information source. </p>

                                    </div>
                                  </div>
                                </div>
                                <div className="elementor-widget-container">
                                  <div className="elementor-icon-box-wrapper">
                                    <div className="elementor-icon-box-content">
                                      <h5 className="elementor-icon-box-title">
                                        <span>4. Your Acceptance of These Terms </span>
                                      </h5>
                                      <p className="elementor-icon-box-description">
                                      By accessing the Website, you are agreeing to the Privacy Policy ShopFiberInternet.com. If you do not agree with this Privacy Policy, please do not access the Website. You should visit this page periodically, as we may, from time to time, we will update this Privacy Policy and such changes effective immediately upon posting on the Site without notice to you. Your continued access to the Website following the posting of changes to this Privacy Policy will be considered acceptance of those changes for you. We, however, use personal information only in accordance with the version of this privacy policy under which the information was provided by you.</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="elementor-widget-container">
                                  <div className="elementor-icon-box-wrapper">
                                    <div className="elementor-icon-box-content">
                                      <h5 className="elementor-icon-box-title">
                                        <span>6. Security </span>
                                      </h5>
                                      <p className="elementor-icon-box-description">
                                      We may update this privacy statement in paragraph reflect changes in our information practices. If we make any changes matter if what you know by email (to the email address specified in your account) or by means of a notice on this Site before the change is Effective Right. We encourage you to check this page periodically for updated information on Privacy Practices.</p>                                    </div>
                                  </div>
                                </div>
                                <div className="elementor-widget-container">
                                  <div className="elementor-icon-box-wrapper">
                                    <div className="elementor-icon-box-content">
                                      <h5 className="elementor-icon-box-title">
                                        <span>6. Security </span>
                                      </h5>
                                      <p className="elementor-icon-box-description">
                                      ShopFiberInternet.com has implemented generally accepted standards of technology and operational security to protect personally identifiable information from loss, misuse, alteration or destruction. All employees follow security policies across the network. Only authorized personnel access to personally identifiable information and these employees have agreed to ensure confidentiality of this information.</p>                                    </div>
                                  </div>
                                </div>
                                <div className="elementor-widget-container">
                                  <div className="elementor-icon-box-wrapper">
                                    <div className="elementor-icon-box-content">
                                      <h5 className="elementor-icon-box-title">
                                        <span>7. Children </span>
                                      </h5>
                                      <p className="elementor-icon-box-description">
                                      We understand the importance of protecting children's privacy, especially in an online environment. ShopFiberInternet.com site covered by this privacy statement are not intentionally designed for or directed at children 13 years old or younger. Policy is not ShopFiberInternet.com knowingly collect or maintains information from children under 13 years</p>  </div>                                </div>
                                </div>
                                <div className="elementor-widget-container">
                                  <div className="elementor-icon-box-wrapper">
                                    <div className="elementor-icon-box-content">
                                      <h5 className="elementor-icon-box-title">
                                        <span>8. Limits on Our Abilities </span>
                                      </h5>
                                      <p className="elementor-icon-box-description">
                                      Although your privacy is very important to us, due to legal and technical environment, we cannot guarantee that your personally identifiable information will not be shared with third parties in ways not described in this policy. Additionally, we can (and you authorize us to) disclose any information about you to private entities, law enforcement or other government officials as we, in our sole discretion, believe necessary or appropriate to address or resolve questions or problems.</p></div></div>                                </div>
                                <div className="elementor-widget-container">
                                  <div className="elementor-icon-box-wrapper">
                                    <div className="elementor-icon-box-content">
                                      <h5 className="elementor-icon-box-title">
                                        <span>9. Complaints Procedure </span>
                                      </h5>
                                      <p className="elementor-icon-box-description">
                                      If you have a complaint regarding any aspect of our service, please email us. Please indicate clearly the nature of the complaint. It is also given a contact name that will keep you informed of the progress of any complaint.


ShopFiberInternet.com may reserve the right to change this online privacy policy from time to time at its discretion. Changes will be posted on the website privacy policy and will be effective upon posting. Continued use of ShopFiberInternet.com after changes in this privacy policy are published online is considered acceptance of those changes. It is your responsibility to oversee privacy policy to determine if such changes have been posted. This privacy policy is subject and all applicable privacy laws.
                                </p>
                                </div>
                                </div>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-14de4161 elementor-widget__width-auto elementor-absolute elementor-hidden-tablet elementor-hidden-mobile elementor-view-default elementor-widget elementor-widget-icon"
                                data-id="14de4161"
                                data-element_type="widget"
                                data-settings='{"_position":"absolute"}'
                                data-widget_type="icon.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="elementor-icon-wrapper">
                                    <div className="elementor-icon">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="28"
                                        height="36"
                                        viewBox="0 0 28 36"
                                        fill="none"
                                      >
                                        <path
                                          d="M20.8818 19.6891C23.3085 19.5511 26.5672 18.7918 27.3992 18.1706C28.4392 17.4114 27.7458 17.2043 25.5271 17.5494C24.4178 17.7565 22.2685 17.9636 20.8125 17.9636C19.3565 17.9636 17.7618 18.1016 17.2765 18.1706C16.5831 18.3087 16.4445 18.2397 16.2365 17.6875C16.0978 17.3424 16.0978 17.0663 16.2365 16.9973C16.6525 16.8592 18.8018 12.3728 18.8711 11.4755C18.9405 10.6473 18.9405 10.6473 18.3858 10.9924C18.1085 11.1994 17.3458 12.0277 16.6525 12.925L15.5431 14.5815L14.2258 9.05976C12.4231 1.26031 11.4525 -0.603281 9.99648 0.155959L9.44182 0.432047L10.2045 2.91683C11.1751 6.16086 12.3538 10.8543 12.9085 13.8913L13.3245 16.307L12.0765 14.7886C10.4818 12.856 8.26314 11.4065 7.43113 11.8206C7.08447 11.9587 6.87648 12.1657 6.87648 12.2348C6.94581 12.5109 11.0365 17.0663 11.9378 17.8945L12.8391 18.7228L9.78849 19.344C6.39115 20.0342 4.51914 20.2413 1.9538 20.3103C0.0124708 20.3793 -0.403521 20.5864 0.359146 21.0695C1.60715 21.8978 4.44981 22.0358 7.84714 21.4147C11.0365 20.8625 11.0365 20.8625 11.1752 21.0005C11.3832 21.2076 9.16448 25.1418 8.67915 25.4869L8.26314 25.763L8.8178 25.9701C9.2338 26.1081 9.58047 26.1081 9.92714 25.9011C10.2045 25.694 10.6205 25.4869 10.7591 25.4179C11.1058 25.2799 11.5218 24.6587 12.9778 22.1739C13.5325 21.2076 14.0871 20.5174 14.2258 20.5864C14.3645 20.7244 15.4045 24.5897 15.6818 25.9701C15.8205 26.5223 17.2072 31.0087 18.0392 33.3554C18.8712 35.7022 19.4952 36.5994 19.0792 34.8049C18.9405 34.2527 18.9405 33.6315 19.0098 33.4244C19.1485 33.1484 17.6925 24.9348 17.0685 22.588C16.9298 22.0359 16.9298 21.6217 16.9992 21.5527C17.1378 21.5527 17.2071 21.6217 17.2765 21.6907C17.2765 21.8288 17.5538 21.8978 17.7618 21.8978C17.9698 21.8978 19.1485 22.381 20.3271 22.9331C21.5058 23.4853 22.5458 23.8304 22.6152 23.7614C22.6845 23.6924 22.6845 23.3473 22.6152 23.0022C22.4765 22.519 21.9218 22.1049 20.1885 21.1386C18.9405 20.4484 18.0391 19.8272 18.2471 19.7581C18.6631 19.8962 19.7032 19.7581 20.8818 19.6891Z"
                                          fill="white"
                                        ></path>
                                      </svg>{" "}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </section>




              <section
                className="elementor-section elementor-inner-section elementor-element elementor-element-aa90d0f elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default parallax_section_no qode_elementor_container_no"
                data-id="aa90d0f"
                data-element_type="section"
                data-settings='{"background_background":"classNameic"}'
              >
                <div className="elementor-container elementor-column-gap-default">
                  <div
                    className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-2ec072d"
                    data-id="2ec072d"
                    data-element_type="column"
                  >
                    <div className="elementor-widget-wrap elementor-element-populated">
                      <div
                        className="elementor-element elementor-element-a4ab7e3 elementor-widget elementor-widget-theme-site-logo elementor-widget-image"
                        data-id="a4ab7e3"
                        data-element_type="widget"
                        data-widget_type="theme-site-logo.default"
                      >
                        <div className="elementor-widget-container">
                          <a href="/">
                            {/* <img
                            // src={logo}
                              width="252"
                              height="40"
                              className="attachment-full size-full"
                              alt="Shop Fiber Internet"
                              loading="lazy"
                            />{" "} */}
                             <p className="attachment-full size-full">Shop Fiber Internet</p>
                          </a>
                        </div>
                      </div>
                      <div
                        className="elementor-element elementor-element-3825041 elementor-widget__width-auto elementor-fixed elementor-hidden-mobile elementor-invisible elementor-widget elementor-widget-image"
                        data-id="3825041"
                        data-element_type="widget"
                        data-settings='{"_position":"fixed","_animation":"fadeInUp"}'
                        data-widget_type="image.default"
                      >
                        <div className="elementor-widget-container">
                          <a href="/">
                            {/* <img
                              width="508"
                              height="924"
                              // src={Logo}
                              className="attachment-full size-full"
                              alt="Connect Now"
                              loading="lazy"
                              // srcset="https://cableinternetinmyarea.com/wp-content/uploads/2022/05/qr.png 508w, https://cableinternetinmyarea.com/wp-content/uploads/2022/05/qr-165x300.png 165w"
                              sizes="(max-width: 508px) 100vw, 508px"
                            />{" "} */}
                            <p className="attachment-full size-full">Shop Fiber Internet</p>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-826ac7b"
                    data-id="826ac7b"
                    data-element_type="column"
                  >
                    <div className="elementor-widget-wrap elementor-element-populated">
                      <div
                        className="elementor-element elementor-element-3024b4a copyright elementor-widget elementor-widget-heading"
                        data-id="3024b4a"
                        data-element_type="widget"
                        data-widget_type="heading.default"
                      >
                        <div className="elementor-widget-container">
                          <div className="elementor-heading-title elementor-size-default">
                            <br />© 2024 By Shop Fiber Internet. All Rights Reserved.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-46f3f25"
                    data-id="46f3f25"
                    data-element_type="column"
                  >
                    <div className="elementor-widget-wrap elementor-element-populated">
                      <div
                        className="elementor-element elementor-element-2ae9d54 elementor-widget elementor-widget-heading"
                        data-id="2ae9d54"
                        data-element_type="widget"
                        data-widget_type="heading.default"
                      >
                        <div className="elementor-widget-container">
                          <div className="elementor-heading-title elementor-size-default">
                            <a href="/privacy-policy/">Privacy Policy</a>
                          </div>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </body>
      </>

    )
  }
}



export default withRouter(Privacy)
