import React from 'react'
import "./servicesStyle.css"
import Map from "../../assests/images/05/map.webp";
import Wifi from "../../assests/images/05/satelite.webp";
import RoundWire from "../../assests/images/05/roundWire.webp";
import ConnectedWire from "../../assests/images/05/connectWire.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
const index = () => {
  return (
    <div className='cardMaindiv'>
          <h2 className='cardMainHeading'>Find The Best Offers Available in your area</h2>
          <div className='imgMap'>
          <LazyLoadImage    effect="blur" src={Map} alt="map"  className='map-img'/>
        </div>
  <div className='container'>
  <h2 className='cardMainHeadingother'>How To Choose The Best Services In My Area</h2>
    <div className='row' >
        <div className='col-md-4'>
         <div className='card-box'>
        
         <LazyLoadImage    effect="blur" src={Wifi} alt="wifi" className='innerimg-card'/>
        
           <h4 className='heading-cable'>Cable Tv Channels</h4> 
           <p>As the First Step you need to decide either you need TV channels and which type of channels attract you more.</p>
         </div>
        </div>
        <div className='col-md-4'>
        <div className='card-box'>
        <LazyLoadImage    effect="blur" src={ConnectedWire} alt="wifi" className='innerimg-card'/>
           <h4 className='heading-cable'>Internet Service</h4> 
           <p>Decide for the internet you need and based on your house member, how much download capacity you need.</p>
         </div>
        </div>
        <div className='col-md-4'>
        <div className='card-box'>
    
        <LazyLoadImage    effect="blur" src={RoundWire} alt="wifi" className='innerimg-card'/>
           <h4 className='heading-cable'>Phone Service</h4> 
           <p>Every house needs a phone service, people go for Bundle offers to cover all their needs with one provider and get some bundle discount offers.</p>
         </div>
        </div>
    </div>
  </div>
  </div>
  )
}

export default index