import Home from "../Containers/Home/Home";
import Privacy from "../Containers/Privacy/Privacy";

export const routes = [
       {
        id:1,
        path: "/",
        component: Home
    },
    {
        id:2,
        path: "/privacy-policy",
        component: Privacy
    },

] 